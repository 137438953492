import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Table from './Items';
import PickTickets from './PickTickets';
import Transfers from './Transfers';
import Builds from './Builds';
import ScrollUpButton from './Common/ScrollUpButton';
import { BsBoxArrowRight } from "react-icons/bs";

const Main = (props) => {
    function logout() {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
    }

    function GetComponent() {
        if (props.match.params.keyword === 'pickTickets')
            return (<PickTickets />)
        else if (props.match.params.keyword === 'transfers')
            return (<Transfers />)
        else if (props.match.params.keyword === 'builds')
            return (<Builds />)
        else
            return (<Table />)
    }

    return (
        sessionStorage.getItem("Email") || localStorage.getItem("Email") ?
            <div className="wrapper">
                <Navbar expand="lg" className="navbar navbar-expand-lg navbar-dark">
                    <Navbar.Brand href="/" className="font-weight-bold">SOS INVENTORY APP</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav className="">
                            <Nav.Link href="/"><span className="links">ITEMS</span></Nav.Link>
                            <Nav.Link href="/pickTickets"><span className="links">PICK-TICKETS</span></Nav.Link>
                            {localStorage.getItem('Company') === 'Berrylush' ? <Nav.Link href="/builds"><span className="links">BUILDS</span></Nav.Link> : null}
                            <Nav.Link href="/transfers"><span className="links">TRANSFERS</span></Nav.Link>
                            <button className="btn btn-outline-light ml-2 rounded-pill" onClick={logout}>
                                <BsBoxArrowRight size="20" />
                            </button>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <GetComponent />
                <ScrollUpButton />
            </div>
            : window.location.href = "/login"
    )
}

export default Main;