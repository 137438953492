import React, { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import swal from 'sweetalert';
import CsvDownload from 'react-json-to-csv';
import { FaArrowDown } from "react-icons/fa";
import { BsArrowLeft, BsArrowRight, BsArrowDown, BsArrowRepeat, BsArrowUp, BsSearch, BsExclamationTriangle, BsCheck } from "react-icons/bs";

function Items() {
    const offset = 200;
    const itemTablekeys = ['image', 'sku', 'category', 'available', 'onHand', 'onWO', 'onPO', 'salesPrice', 'purchaseCost', 'reorderPoint', 'alert', 'action'];
    const categories = ['All', 'FABRIC', 'ZIP', 'TRIMS', 'PROCESS', 'FINISHED GOODS', 'DISCOUNTINUED'];
    const [itemsData, setItemsData] = useState(null);
    const [category, setCategory] = useState('All');
    const [pageNo, setPageNo] = useState(1);
    const [maxPages, setMaxPages] = useState(5);
    const [totalItems, setTotalItems] = useState(null);
    const [sort, setSort] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortingKey, setSortingKey] = useState(null);
    const [searchSKU, setSearchSKU] = useState('');
    const [flagFetchData, setFlagFetchData] = useState(false);
    const [updateItemData, setUpdateItemData] = useState({});
    const [syncing, setSyncing] = useState(false);

    const fetchData = async () => {
        await axios.post('https://sosapi.lyzer.io/getItems', {
            page: pageNo,
            limit: offset,
            company: localStorage.getItem('Company'),
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            category: category === 'All' ? null : category,
            sku: searchSKU || null
        }).then(function (res) {
            if (res.data.result === 'success') {
                setTotalItems(res.data.data.total);
                setItemsData(res.data.data.items);
                setMaxPages(Math.ceil(res.data.data.total / offset))
            } else {
                alert(res.data.msg)
            }
        })
    };

    useEffect(() => {
        setItemsData(null);
        fetchData();
    }, [pageNo, offset, flagFetchData]);

    const changeData = (type, value = null) => {
        switch (type) {
            case 'search':
                setPageNo(1);
                setCategory('All');
                break;
            case 'category':
                setCategory(value)
                setSearchSKU("");
                setPageNo(1);
                break;
            default:
                break;
        }
        setFlagFetchData(!flagFetchData);
    }

    const updateItemsState = (sku, value, key, id) => {
        let data = _.get(updateItemData, sku, {});
        data[key] = value;
        const final = updateItemData;
        final[sku] = { ...data, sku, id };
        setUpdateItemData(final);
    }

    const updateItemDataToSos = (sku = null) => {
        try {
            let data = sku ? _.get(updateItemData, sku, null) : updateItemData;
            if (data) {
                axios.post("https://sosapi.lyzer.io/updateItem",
                    {
                        company: localStorage.getItem('Company'),
                        token: localStorage.getItem('AccessToken'),
                        email: sessionStorage.getItem('Email'),
                        item: data
                    })
                    .then(res => {
                        if (res.data && res.data.result === 'success' && _.isObject(res.data.data)) {
                            swal("Success!", "Successfully Updated", 'success');
                            window.setTimeout(setFlagFetchData(!flagFetchData), 3000);
                        } else {
                            swal("Error!", res.data.msg, 'error');
                        }
                    }).catch(error => {
                        throw error;
                    });
            } else {
                swal('Warning!', 'No updated data found for this SKU...', 'warning');
            }
        } catch (error) {
            console.log('error while updating item', error);
            swal("Error!", "Something went wrong...", 'error');
        }
    }

    const syncItems = async () => {
        setSyncing(true);
        await axios.post('http://sosapi.lyzer.io/sync/items', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company')
        }).then(res => {
            if (res.data.result === 'success') {
                swal("Success!", "Successfully Updated", 'success');
            } else {
                swal("Error!", "Error while updating", 'error');
            }
            setSyncing(false);
        }).catch(error => {
            swal('Error!', 'Something went wrong while syncing', 'error');
            console.log(error);
            setSyncing(false);
        })
    }

    const dynamicsort = (property, order) => {
        var sort_order = 1;
        if (order === "desc") {
            sort_order = -1;
        }
        return function (a, b) {
            // a should come before b in the sorted order
            if (a[property] < b[property]) {
                return -1 * sort_order;
                // a should come after b in the sorted order
            } else if (a[property] > b[property]) {
                return 1 * sort_order;
                // a and b are the same
            } else {
                return 0 * sort_order;
            }
        }
    }

    const handleSorting = key => {
        // key = key === 'sku' ? 'name' : key;
        let order = sortOrder
        if (sortingKey === key) {
            if (order === 'desc')
                order = 'asc'
            else
                order = 'desc'
        } else if (sortingKey !== key) {
            order = 'asc'
        }
        setSortingKey(key)
        let data = itemsData.sort(dynamicsort(key, order));
        setItemsData(data)
        setSortOrder(order)
        setSort(!sort)
    }

    return (
        <div className="tableDiv">
            <div className="category-div">
                {
                    localStorage.getItem('Company') === 'Berrylush' ?
                        <div className="form-group">
                            <label htmlFor="sel1">Select Category:</label>
                            <select className="form-control"
                                onChange={(e) => changeData('category', e.target.value)}
                                value={category}>
                                {
                                    categories.map((value, key) => <option value={value} key={key}>{value.toUpperCase()}</option>)
                                }
                            </select>
                        </div>
                        : ''
                }
            </div>
            <div className="top-div py-4">
                <div className="top-div_pagination">
                    <button className="btn-sm mr-3" onClick={() => {
                        setPageNo(pageNo - 1);
                        setItemsData(null)
                    }} disabled={pageNo === 1 ? true : false}>Prev <BsArrowLeft /></button>
                    <button className="btn-sm" onClick={() => {
                        setPageNo(pageNo + 1);
                        setItemsData(null)
                    }} disabled={maxPages === pageNo ? true : false}>Next <BsArrowRight /></button>
                    {itemsData ?
                        <span className="ml-2">{offset * (pageNo - 1) + 1} - {offset * pageNo} / {totalItems} Items</span>
                        : null}
                </div>
                <div className="top-div_export">
                    <CsvDownload data={itemsData} filename="items.csv" className="btn export-csv">
                        <FaArrowDown className="export_icon" /> Export CSV
                        </CsvDownload>
                    {syncing ?
                        <button className="btn main-btn mr-2" disabled>
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                 syncing...
                            </button> :
                        <button className="btn main-btn mr-2" onClick={() => syncItems()}>
                            <BsArrowRepeat /> Sync</button>
                    }
                </div>
                <div className="top-div_search">
                    <div className="form-group" style={{ display: 'flex' }}>
                        <input type="text" className="form-control" placeholder="Search for SKU..."
                            onChange={(e) => setSearchSKU(e.target.value)} value={searchSKU} />
                        <button className="btn main-btn" onClick={() => changeData('search')}><BsSearch /></button>
                    </div>
                </div>
            </div>
            <table className="table table-hover text-center mt-4">
                <thead>
                    <tr>
                        {itemTablekeys.map(value => {
                            return (
                                <th key={value} onClick={() => handleSorting(value)}>
                                    {value}{sortingKey === value ? (sortOrder === 'asc' ?
                                        <BsArrowUp />
                                        :
                                        <BsArrowDown />) : ''}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        itemsData ?
                            itemsData.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td valign="middle" key={key + "_image"}><img src={item.image ? item.image : require('../assets/noImage.png')} alt={item.name} width="50" /></td>
                                        <td valign="middle" key={key + "_name"}>{item.sku}</td>
                                        <td valign="middle" key={key + "_category"}>{item.category}</td>
                                        <td valign="middle" key={key + "_available"}>{item.available}</td>
                                        <td valign="middle" key={key + "_onhand"}>{item.onHand}</td>
                                        <td valign="middle" key={key + "_onWO"}>{item.onWO}</td>
                                        <td valign="middle" key={key + "_onPO"}>{item.onPO}</td>
                                        <td valign="middle" key={key + "_salesPrice"}>{item.salesPrice}</td>
                                        <td valign="middle" key={key + "_purchaseCost"}>
                                            <input
                                                type="number"
                                                min={0}
                                                defaultValue={item.purchaseCost}
                                                onChange={(e) => updateItemsState(item.sku, e.target.value, 'purchaseCost', item.itemSosId)}
                                                style={{ width: '50%' }}
                                            />
                                        </td>
                                        <td valign="middle" key={key + "_reorderPoint"}>
                                            <input
                                                type="number"
                                                min={0}
                                                defaultValue={item.reorderPoint}
                                                onChange={(e) => updateItemsState(item.sku, e.target.value, 'reorderPoint', item.itemSosId)}
                                                style={{ width: '50%' }}
                                            />
                                        </td>
                                        <td valign="middle" key={key + "_alert"}>
                                            {item.alert ? <BsExclamationTriangle color="red" />
                                                : <BsCheck color="green" />}
                                        </td>
                                        <td valign="middle" key={key + "_action"}>
                                            <button
                                                className="btn main-btn"
                                                disabled={false}
                                                onClick={() => updateItemDataToSos(item.sku)}
                                            > Update
                                            </button>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <div className="loader">
                                <div className="spinner-border"></div>
                                <h4>Fetching data...</h4>
                            </div>
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Items;