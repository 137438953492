import React, { useState, useEffect } from 'react';
import { BsArrowUp } from 'react-icons/bs';


const ScrollUpButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        isVisible &&
        <button className="scroll-up-btn" onClick={() => scrollToTop()}>
            <BsArrowUp />
        </button>
    );
}

export default ScrollUpButton;