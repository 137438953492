import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert';

function Login(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [remember, setRemember] = useState(false)
    const [auth, setAuth] = useState(false)

    useEffect(() => {
        if (sessionStorage.getItem("Email") || localStorage.getItem("Email")) {
            setAuth(true);
        }
    }, []);

    function handleLogin(event) {
        event.preventDefault();
        axios({
            method: 'post',
            url: 'http://sosapi.lyzer.io/login',
            data: JSON.stringify({ email: email, password: password })
        }).then(function (response) {
            if (response.data.result === 'success') {
                localStorage.setItem('Company', response.data.company);
                localStorage.setItem('Password', password);
                localStorage.setItem('AccessToken', response.data.token)
                sessionStorage.setItem('Email', email);
                setAuth(true);
                if (remember) {
                    localStorage.setItem('Email', email);
                }
                window.location.href = "/";
            } else {
                Swal(response.data.result, response.data.msg, response.data.result)
                setAuth(false)
            }
        });
    }

    return (
        auth ? window.location.href = "/" :
            <form className="login text-center was-validated" onSubmit={(e) => handleLogin(e)}>
                <h1 className="mb-4 heading">SOS Inventory App</h1>
                <h3 className="h3 mb-3 font-weight-normal">Please Login First</h3>
                <label className="sr-only" htmlFor="inputEmail">Email address</label>
                <input type="email" id="inputEmail" className="form-control mb-2" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} required autoFocus />
                <label className="sr-only" htmlFor="inputPassword">Password</label>
                <input type="password" id="inputPassword" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                <div className="checkbox mb-3">
                    <label>
                        <input type="checkbox" value="remember-me" onChange={(e) => setRemember(e.target.checked)} /> Remember me
                </label>
                </div>
                <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            </form>
    )
}

export default Login;