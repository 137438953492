import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
import { BsPencilSquare, BsCheck, BsArrowLeft } from 'react-icons/bs';

function PickTickets() {
    const [ticketsData, setTicketsData] = useState(null)
    const [locations, setLocations] = useState(null)
    const [ticket, setTicket] = useState(null)
    const [createTransferClicked, setCreateTransferClicked] = useState(false)
    const [ticketClicked, setTicketClicked] = useState(false)
    const [spinner, setSpinner] = useState(false)
    const [transfer, setTransfer] = useState(null)
    const [qty, setQty] = useState(null)
    const [maxQty, setMaxQty] = useState(null)
    const [Key, setKey] = useState(null)
    const [changeTO, setChangeTO] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [TO, setTO] = useState(null)
    const [fromLocation, setFromLocation] = useState(null)
    const [toLocation, setToLocation] = useState(null)

    const company = localStorage.getItem('Company').toLowerCase()

    useEffect(() => {
        if (ticketsData === null) {
            fetchData();
        }
    }, [ticketsData]);

    async function fetchData() {
        await axios.post('http://sosapi.lyzer.io/pickTickets', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company')
        }).then(function (res) {
            if (res.data.result === 'success') {
                setTicketsData(res.data.data.pickTickets)
                setLocations(res.data.data.locations)
            } else
                alert(res.data.msg)
        })
    }

    async function modifyTransferData(data) {
        await axios.post('http://sosapi.lyzer.io/modifyPickTicket', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: company,
            pickTicket: JSON.stringify(data)
        }).then(function (res) {
            if (res.data.result === 'success') {
                setCreateTransferClicked(true)
                setShowModal(true)
                setTransfer(res.data.data)
                setTO("TO-" + ticket.id)
            }
            else
                alert(res.data.msg)
        })
    }

    function SettingTicketData(id) {
        setTicketClicked(true)
        setTransfer(null)
        ticketsData.forEach(element => {
            if (element.id === parseInt(id)) {
                setTicket({ id, "wo": element.number, "line": element.lines });
                return;
            }
        });
    }

    function setQuantity(qty, key) {
        let currQty = parseFloat(maxQty && Key === key ? maxQty : transfer[key]['quantity'])
        if (qty >= 0 && qty <= currQty) {
            if (Key === null || Key !== key) {
                setKey(key)
                setMaxQty(transfer[key]['quantity'])
            }
            setQty(qty)
            console.log('max', currQty)
            transfer[key]['quantity'] = qty;
            console.log('qty', qty)
            console.log('Transfer', transfer[key])
        } else {
            alert('Maximum allowed quantity is - ' + currQty);
        }
    }

    function createTransfer() {
        const allowed = ["ZIP","FABRIC"];
        let data = [], temp = [];
        for (let i = 0; i < ticket.line.length; i++) {
            let name = ticket['line'][i].item.name;
            if (allowed.some(substring => name.includes(substring)))
                if (!temp.includes(ticket['line'][i].item.id)) {
                    temp.push(ticket['line'][i].item.id)
                    for (let j = i + 1; j < ticket['line'].length; j++) {
                        if (ticket['line'][i].item.id === ticket['line'][j].item.id) {
                            ticket['line'][i].quantity += ticket['line'][j].quantity;
                        }
                    }
                    data.push(ticket['line'][i])
                }
        }
        modifyTransferData(data)
    }

    function saveTransfer() {
        if (fromLocation && toLocation) {
            setSpinner(true)
            let date = new Date();
            date.toISOString();
            axios.post('https://www.razorprime.com/SosInventory/createTransfer', {
                token: localStorage.getItem('AccessToken'),
                email: sessionStorage.getItem('Email'),
                company: localStorage.getItem('Company'),
                data: {
                    number: TO,
                    date: date,
                    fromLocation: {
                        "id": fromLocation,
                        "name": locations[fromLocation]
                    },
                    toLocation: {
                        "id": toLocation,
                        "name": locations[toLocation]
                    },
                    lines: transfer
                }
            }).then(function (res) {
                setSpinner(false)
                if (res.data.result === 'success') {
                    swal('Success', "Transfer order created successfully with Ref# " + TO, 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                } else
                    alert(res.data.msg)
            })

        } else {
            setShowModal(true)
        }
    }

    return (
        sessionStorage.getItem("Email") || localStorage.getItem("Email") ?
            <div className="wrapper">
                <h1 className="text-center mt-5">Pick Tickets</h1>
                {ticketsData ?
                    <div className="main">
                        {!ticketClicked ?
                            // showing pick tickets table
                            <div className="tableDiv">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th key="id">#</th>
                                            <th key="number">Work Order</th>
                                            <th key="date">Date</th>
                                            <th key="location">Location</th>
                                            <th key="status">Status</th>
                                            <th key="memo">Memo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ticketsData.map((value, key) => {
                                                return (<tr key={key}>
                                                    <td key={key + '-id'}><button className="link-btn" onClick={() => SettingTicketData(value.id)}>{value.id}</button></td>
                                                    <td key={key + '-number'}>{value.number}</td>
                                                    <td key={key + '-date'}>{value.date}</td>
                                                    <td key={key + '-location'}>{value.location.name}</td>
                                                    <td key={key + '-status'}>{value.archived ? 'closed' : 'open'}</td>
                                                    <td key={key + '-comment'}>{value.comment}</td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            createTransferClicked && transfer ?
                                <div className="tableDiv">
                                    <div className="infoDiv pb-4">
                                        <span><h4 className="mr-2">Transfer Order :</h4></span>
                                        {changeTO ? <span>
                                            <input type="text" value={TO} onChange={event => setTO(event.target.value)} />
                                            <BsCheck className="ml-2" onClick={() => setChangeTO(false)} />
                                        </span>
                                            : <span>
                                                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{TO}</span>
                                                <BsPencilSquare className="ml-2" onClick={() => setChangeTO(true)} />
                                            </span>
                                        }
                                        {spinner ?
                                            <button className="btn main-btn" disabled>
                                                <span className="spinner-border spinner-border-sm mr-2"></span>
                                         Saving...
                                    </button> : <button className="btn main-btn" onClick={() => saveTransfer()}>Save Transfer</button>}
                                        <button className="btn main-btn mr-2" onClick={() => setShowModal(true)}>Edit locations</button>
                                    </div>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th key="id">Id</th>
                                                <th key="itemId">Item Id</th>
                                                <th key="name">Name</th>
                                                <th key="desc">Description</th>
                                                <th key="onhand">OnHand</th>
                                                <th key="bin">Bin</th>
                                                <th key="pickQty">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                transfer.map((value, key) => {
                                                    return (<tr key={key}>
                                                        <td key={key + '-number'}>{value['lineNumber']}</td>
                                                        <td key={key + '-id'}>{value['item']['id']}</td>
                                                        <td key={key + '-name'}>{value['item']['name']}</td>
                                                        <td key={key + '-desc'}>{value['description']}</td>
                                                        <td key={key + '-onhand'}>{'onhand' in value ? value['onhand'] : '-'}</td>
                                                        <td key={key + '-bin'}>{value['bin'] || '-'}</td>
                                                        <td key={key + '-qty'}><input type="text" value={qty && Key === key ? qty : value['quantity']}
                                                            onChange={event => setQuantity(event.target.value, key)} /></td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="tableDiv">
                                    <div className="infoDiv pb-4">
                                        <button className="btn btn-secondary mr-2" style={{ float: "left" }} onClick={() => setTicketClicked(false)}><BsArrowLeft /> Back</button>
                                        <h4>Pick Ticket Id : {ticket['id']}</h4>
                                        <button className="btn main-btn" onClick={() => createTransfer()}>Create Transfer</button>
                                    </div>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th key="id">Id</th>
                                                <th key="itemId">Item Id</th>
                                                <th key="name">Name</th>
                                                <th key="desc">Description</th>
                                                <th key="pickQty">Pick QTY</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ticket['line'].map((value, key) => {
                                                    return (<tr key={key}>
                                                        <td key={key + '-number_base'}>{value['lineNumber']}</td>
                                                        <td key={key + '-id_base'}>{value['item']['id']}</td>
                                                        <td key={key + '-name_base'}>{value['item']['name']}</td>
                                                        <td key={key + '-desc_base'}>{value['description']}</td>
                                                        <td key={key + '-qty_base'}>{value['quantity']}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </div>
                    :
                    <div className="loader text-center">
                        <div className="spinner-border"></div>
                        <h4>Fetching Pick Tickets...</h4>
                    </div>
                }
                {showModal ?
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Select from and to locations:</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label>From Location:</label>
                                <select className="form-control" onChange={(e) => setFromLocation(e.target.value)} defaultValue={fromLocation}>
                                    <option key="NoFrom" >Select from location</option>
                                    {
                                        Object.keys(locations).map((value, key) =>
                                            <option value={value} key={key} >{locations[value]}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className="form-group">
                                <label>To Location:</label>
                                <select className="form-control" onChange={(event) => setToLocation(event.target.value)} defaultValue={toLocation}>
                                    <option key="NoFrom">Select to location</option>
                                    {
                                        Object.keys(locations).map((value, key) =>
                                            <option value={value} key={key} >{locations[value]}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => setShowModal(false)}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal> : ''}
            </div>
            : window.location.href = "/login"

    );
}

export default PickTickets;