import React, { useState, useEffect } from 'react'
import axios from 'axios'

function Transfers() {
    const [transfersData, seTransfersData] = useState(null)

    useEffect(() => {
        if (transfersData === null)
            fetchData();
    }, [transfersData]);

    async function fetchData() {
        await axios.post('https://www.razorprime.com/SosInventory/getTransfers', {
            password: localStorage.getItem('Password'),
            company: localStorage.getItem('Company').toLowerCase()
        }).then(function (res) {
            if (res.data.result === 'success') {
                res = JSON.parse(res.data.data)
                seTransfersData(res)
            } else {
                alert(res.data.msg)
            }
        })
    }

    return (
        sessionStorage.getItem("Email") || localStorage.getItem("Email") ?
            <div className="wrapper">
                <div className="text-center">
                    <h1 className="pt-5">Transfers</h1>
                    {transfersData ?
                        <div className="tableDiv">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr key="headers">
                                        <th key="id">Id</th>
                                        <th key="date">Date</th>
                                        <th key="from">From</th>
                                        <th key="to">To</th>
                                        <th key="ref">Ref #</th>
                                        <th key="comment">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transfersData.data.map((value, key) => {
                                            return (<tr key={value.id}>
                                                <td key={value.id + "-id"}>{value.id}</td>
                                                <td key={value.id + "-date"}>{value.date}</td>
                                                <td key={value.id + "-from"}>{value.fromLocation.name}</td>
                                                <td key={value.id + "-to"}>{value.toLocation.name}</td>
                                                <td key={value.id + "-ref"}>{value.number}</td>
                                                <td key={value.id + "-comment"}>{value.comment ? value.comment : "-"}</td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="loader text-center">
                            <div className="spinner-border"></div>
                            <h4>Fetching Transfers...</h4>
                        </div>}
                </div>

            </div>
            : window.location.href = "/login"
    );
}

export default Transfers;