import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Main from './Main';
import Login from './Login';

function Router() {
    const NotFound = () => (<h1>404.. This page is not found!</h1>)
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/not-found' exact component={NotFound} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/" component={Main} />
                <Route exact path="/:keyword" component={Main}/>
            </Switch>
        </BrowserRouter>
    );
}

export default Router;