import React, { useState, useEffect } from 'react'
import axios from 'axios'
import swal from 'sweetalert'
import { Modal, Button } from 'react-bootstrap'
import { BsArrowLeft, BsPlus, BsCheckCircle, BsArrowRepeat } from 'react-icons/bs'

function Builds() {
    const [buildsData, setBuildsData] = useState(null)
    const [processData, setProcessData] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [selectedBuild, setSelectedBuild] = useState(null)
    const [processType, setProcessType] = useState("Cutting")
    const [date, setDate] = useState(null)
    const [comment, setComment] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [saving, setSaving] = useState(false)
    const [forceUpdate, setForceUpdate] = useState(false)
    const [forceUpdatingBuild, setForceUpdatingBuild] = useState(null)
    const [syncing, setSyncing] = useState(false);

    useEffect(() => {
        if (buildsData === null)
            fetchBuildsData();
    }, [buildsData]);

    async function fetchBuildsData() {
        await axios.post('http://sosapi.lyzer.io/build', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company')
        }).then(function (res) {
            if (res.data.result === 'success') {
                // res.data.data.sort(function (a, b) {
                //     return a.date.localeCompare(b.date);
                // });
                setBuildsData(res.data.data)
            } else {
                alert(res.data.msg)
            }
        })
    }

    async function syncBuilds() {
        setSyncing(true);
        await axios.post('http://sosapi.lyzer.io/syncBuilds', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company')
        }).then(function (res) {
            if (res.data.result === 'success') {
                setSyncing(false)
                swal("Success!", "Successfully Updated", 'success')
                // setBuildsData(res.data.data)
            } else {
                swal("Error!", "Error while updating", 'error')
            }
        })
    }

    async function forceUpdateBuilds(updateThisBuild = null) {
        let updateBuilds = {};
        if (updateThisBuild) {
            updateBuilds[updateThisBuild] = buildsData[updateThisBuild].buildSOSId;
            setForceUpdatingBuild(updateThisBuild)
        } else {
            for (let build in buildsData) {
                updateBuilds[build] = buildsData[build].buildSOSId;
            }
            setForceUpdate(true)
        }
        await axios.post('http://sosapi.lyzer.io/updateBuildToSOS', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company'),
            builds: updateBuilds
        }).then(function (res) {
            console.log(res.data)
            setForceUpdate(false)
            if (res.data.result === 'success') {
                swal("Success!", "Successfully Updated", 'success')
                setForceUpdatingBuild(null)
                setForceUpdate(false)
                // setBuildsData(res.data.data)
            } else {
                swal("Error!", "Error while updating", 'error')
            }
        })
    }

    async function fetchProcesses(buildNo) {
        setSelectedBuild(buildNo)
        await axios.post('http://sosapi.lyzer.io/process', {
            token: localStorage.getItem('AccessToken'),
            email: sessionStorage.getItem('Email'),
            company: localStorage.getItem('Company'),
            buildNo: buildNo
        }).then(function (res) {
            if (res.data.result === 'success') {
                setProcessData(res.data.data)
            } else {
                alert(res.data.msg)
            }
        })
    }

    async function saveProcess() {
        if (date && quantity) {
            setSaving(true)
            await axios.post('http://sosapi.lyzer.io/newProcess', {
                token: localStorage.getItem('AccessToken'),
                email: sessionStorage.getItem('Email'),
                company: localStorage.getItem('Company'),
                buildNo: selectedBuild,
                date: date,
                sku: buildsData[selectedBuild].sku,
                type: processType,
                qty: quantity,
                comment: comment
            }).then(function (res) {
                if (res.data.result === 'success') {
                    setSaving(false);
                    setShowModal(false);
                    alert(res.data.msg);
                    fetchProcesses(selectedBuild)
                } else {
                    alert(res.data.msg)
                }
            })
        }
        else
            alert('Please fill all required fields to create new process')
    }

    return (
        sessionStorage.getItem("Email") || localStorage.getItem("Email") ?
            <div className="wrapper">
                <div className="text-center">
                    <h1 className="pt-5">Builds & Processes</h1>
                    {buildsData ? processData ?
                        <div className="tableDiv">
                            <div className="upperDiv mb-5">
                                <button className="btn btn-secondary" style={{ float: "left" }} onClick={() => window.location.reload()}><BsArrowLeft /> Back</button>
                                <h4 className="mt-1 ml-2" style={{ float: "left" }}>Build No: <span style={{ color: "#563d7c" }}>{selectedBuild}</span></h4>
                                <button className="btn main-btn" style={{ float: "right" }} onClick={() => setShowModal(!showModal)}><BsPlus /> Add Process</button>
                            </div>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr key="headers">
                                        <th key="id">Process ID</th>
                                        <th key="sku">SKU</th>
                                        <th key="qty">Quantity</th>
                                        <th key="type">Type</th>
                                        <th key="date">Date</th>
                                        <th key="comments">Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        processData.map(value => {
                                            return (<tr key={value.id}>
                                                <td key={value.id + "-id"}>{value.id}</td>
                                                <td key={value.id + "-sku"}>{value.sku}</td>
                                                <td key={value.id + "-qty"}>{value.qty}</td>
                                                <td key={value.id + "-type"}>{value.type}</td>
                                                <td key={value.id + "-date"}>{Date(value.date)}</td>
                                                <td key={value.id + "-comments"}>{value.comments || '-'}</td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                            {processData.length === 0 ?
                                <div className="py-5 notFound">
                                    <h3>No Record Found</h3>
                                </div>
                                : ''}
                        </div>
                        :
                        <div className="tableDiv">
                            {forceUpdate ?
                                <button className="btn main-btn mb-2" style={{ float: "right" }} disabled>
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                         Updating...
                                    </button> :
                                <button className="btn main-btn mb-2" style={{ float: "right" }} onClick={() => forceUpdateBuilds()}>
                                    <BsCheckCircle /> Force Update All</button>}
                            {syncing ?
                                <button className="btn main-btn mb-2 mr-2" style={{ float: "right" }} disabled>
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                 syncing...
                            </button> :
                                <button className="btn main-btn mb-2 mr-2" style={{ float: "right" }} onClick={() => syncBuilds()}>
                                    <BsArrowRepeat /> Sync</button>}
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr key="headers">
                                        <th key="id">Build No.</th>
                                        <th key="date">Date</th>
                                        <th key="sku">SKU</th>
                                        <th key="qty">Quantity</th>
                                        <th key="cutQty">Cut Qty</th>
                                        <th key="fabQty">Fab Qty</th>
                                        <th key="packQty">Pack Qty</th>
                                        <th key="action">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(buildsData).map(key => {
                                            return (<tr key={buildsData[key].buildNo}>
                                                <td key={buildsData[key].buildNo + "-id"}><button className="link-btn" onClick={(e) => fetchProcesses(buildsData[key].buildNo)}>{buildsData[key].buildNo}</button></td>
                                                <td key={buildsData[key].buildNo + "-date"}>{Date(buildsData[key].date)}</td>
                                                <td key={buildsData[key].buildNo + "-sku"}>{buildsData[key].sku}</td>
                                                <td key={buildsData[key].buildNo + "-qty"}>{buildsData[key].qty}</td>
                                                <td key={buildsData[key].buildNo + "-cutQty"}>{buildsData[key].cutQty || 0}</td>
                                                <td key={buildsData[key].buildNo + "-fabQty"}>{buildsData[key].fabQty || 0}</td>
                                                <td key={buildsData[key].buildNo + "-packQty"}>{buildsData[key].packQty || 0}</td>
                                                <td key={buildsData[key].buildNo + "-action"}>
                                                    {forceUpdatingBuild && forceUpdatingBuild === buildsData[key].buildNo ?
                                                        <button className="btn main-btn mb-2" disabled>
                                                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                                             Updating...
                                                        </button>
                                                        :
                                                        <button className="btn btn-sm main-btn" onClick={() => forceUpdateBuilds(buildsData[key].buildNo)}>Save to SOS</button>
                                                    }
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </table>
                            {Object.keys(buildsData).length === 0 ?
                                <div className="py-5 notFound">
                                    <h3>No Record Found</h3>
                                </div>
                                : ''}
                        </div>
                        :
                        <div className="loader text-center">
                            <div className="spinner-border"></div>
                            <h4>Fetching Builds...</h4>
                        </div>}
                    {showModal ?
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Complete new process data:</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <p>Build No : <strong className="ml-3">{selectedBuild}</strong></p>
                                </div>
                                <div className="form-group">
                                    <p>SKU : <strong className="ml-5">{buildsData[selectedBuild].sku}</strong></p>
                                </div>
                                <div className="form-group">
                                    <label>Process Type <span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" onChange={(event) => setProcessType(event.target.value)} defaultValue={processType}>
                                        <option value="Cutting" key="Cutting">Cutting</option>
                                        <option value="Fabrication" key="Fabrication">Fabrication</option>
                                        <option value="Packing" key="Packing">Packing</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Date <span style={{ color: "red" }}>*</span></label>
                                    <input type="date" className="form-control" max="today" onChange={(e) => setDate(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>Quantity <span style={{ color: "red" }}>*</span></label>
                                    <input type="number" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label>Comment</label>
                                    <textarea className="form-control" cols="40" rows="4" onChange={(e) => setComment(e.target.value)} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                            </Button>
                                {saving ?
                                    <button className="btn btn-primary" disabled>
                                        <span className="spinner-border spinner-border-sm mr-2"></span>
                                         Saving...
                                    </button> : <button className="btn btn-primary" onClick={() => saveProcess()}>Save Process</button>}
                            </Modal.Footer>
                        </Modal> : ''}
                </div>

            </div>
            : window.location.href = "/login"
    );
}

export default Builds;